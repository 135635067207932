import Swiper, {EffectFade} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let commitmentSwiperWr = document.querySelectorAll('.locations-swiper-wr');
commitmentSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
     let tabs = el.querySelectorAll('.locations-swiper-tab');
    let swiper = new Swiper(swiperEl, {
      modules: [EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 1,
      allowTouchMove: false,
      speed: 800,
      spaceBetween: 0,
      effect: 'fade',
      autoHeight: true,
      fadeEffect: {
        crossFade: true,
      },
      on: {
        afterInit: function(){
          tabs.forEach((tab, idx)=>{

            tab.addEventListener('click', function(){
              tab.classList.add('active');
              swiper.slideTo(idx);
              tabs.forEach((el, i)=>{
                if(idx != i) {
                  el.classList.remove('active');
                }
              })
            })
          })
        }
      }
    });
    swiperObserver(swiper);
  }
});
