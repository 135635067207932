import aiv from '../libs/aiv.lib';

aiv({ once: true });

window.aivInit = function aivInit() {
  aiv({ once: true });
};

if (document.querySelector('[data-splitting]')) {
  const splittingArr = document.querySelectorAll('[data-splitting]');
  splittingArr.forEach((item, i) => {
    if (item.hasAttribute('data-splitting-char') === true) {
      const splitting = Splitting({
        target: item,
      });
    } else {
      const splitting = Splitting({
        target: item,
        by: 'words',
      });
    }
  });
}
