if (document.querySelector('.expertise-tabs')) {
  const expertiseTabs = document.querySelectorAll('.expertise-tabs');
  expertiseTabs.forEach((tab) => {
    const tabItemsArr = tab.querySelectorAll('.expertise-tabs__item');

    tabItemsArr.forEach((item, i) => {
      const btn = item.querySelector('.expertise-tabs__item-top');

      item.addEventListener('click', (ev) => {
        if (!item.classList.contains('active')) {
          item.classList.add('active');
        } else {
          /*if (ev.target === btn) {
            item.classList.remove('active');
          }*/
          item.classList.remove('active');
        }
      });
    });
  });

  const expertiseTabsContentContainers = document.querySelectorAll(
    '.expertise-content-container'
  );
  const expertiseTabsContainers = document.querySelectorAll(
    '.expertise-tabs-container'
  );

  function setMaxHeight() {
    expertiseTabsContainers.forEach((tabsContainer, index) => {
      const contentContainer = expertiseTabsContentContainers[index];

      if (contentContainer && tabsContainer) {
        const contentContainerHeight = contentContainer.offsetHeight;

        tabsContainer.style.maxHeight = `calc(100% - ${contentContainerHeight}px)`;
      }
    });
  }

  setMaxHeight();

  window.addEventListener('resize', setMaxHeight);
}
