import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let tsSwiperWr = document.querySelectorAll('.ts-swiper-wr');
tsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 1,
      speed: 800,
      spaceBetween: 15,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        delay: 2500,
      },
      effect: 'creative',
      creativeEffect:{
        prev:{
          translate: [0, 0, -400],
        },
        next:{
          translate: ['100%', 0, 0],
        }
      }
      /* autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },*/
    });
    swiperObserver(swiper);
  }
});
