import Choices from 'choices.js';

window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll('textarea[data-autosize]');
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
window.autosizeTextarea();

const choices = document.querySelectorAll('.js-choice');

choices.forEach((choice) => {
  new Choices(choice, {
    searchEnabled: false,
    itemSelectText: '',
  });
});

/* Enforce phone number pattern for tel input */
const telInputs = document.querySelectorAll('input[type="tel"]');

function enforcePattern(event) {
  const input = event.target;
  const value = input.value;
  const pattern = /^\+?[0-9]*$/;

  if (!pattern.test(value)) {
    input.value = input.dataset.lastValidValue || '';
  } else {
    input.dataset.lastValidValue = value;
  }
}

telInputs.forEach((input) => {
  input.dataset.lastValidValue = input.value;
  input.addEventListener('input', enforcePattern);
});
