if (document.getElementsByClassName('add-services-card__hidden-btn')[0]) {
  const addServicesButtons = document.querySelectorAll(
    '.add-services-card__hidden-btn'
  );

  addServicesButtons.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      event.stopPropagation();
      if (!btn.classList.contains('active')) {
        for (let i = 0; i < addServicesButtons.length; i++) {
          addServicesButtons[i].classList.remove('active');
        }
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
  });

  document.addEventListener('click', (event) => {
    addServicesButtons.forEach((btn) => {
      if (!btn.contains(event.target)) {
        btn.classList.remove('active');
      }
    });
  });
}

if (document.getElementsByClassName('benefits-card__hidden-btn')[0]) {
  const addServicesButtons = document.querySelectorAll(
    '.benefits-card__hidden-btn'
  );

  addServicesButtons.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      event.stopPropagation();
      if (!btn.classList.contains('active')) {
        for (let i = 0; i < addServicesButtons.length; i++) {
          addServicesButtons[i].classList.remove('active');
        }
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
  });

  document.addEventListener('click', (event) => {
    addServicesButtons.forEach((btn) => {
      if (!btn.contains(event.target)) {
        btn.classList.remove('active');
      }
    });
  });
}

if (document.getElementsByClassName('add-services-card__title')[0]) {
  const addServicesCardTitleArr = document.querySelectorAll(
    '.add-services-card__title'
  );

  let maxHeight = 0;

  addServicesCardTitleArr.forEach((title) => {
    const titleHeight = title.offsetHeight;
    if (titleHeight > maxHeight) {
      maxHeight = titleHeight;
    }
  });

  addServicesCardTitleArr.forEach((title) => {
    title.style.minHeight = `${maxHeight}px`;
  });
}

const btnShowHideArr = document.querySelectorAll('.btn-show-hide');
const showHideContentArr = document.querySelectorAll('.show-hide-content');

if (document.querySelector('.show-hide-content')) {
  showHideContentArr.forEach((content, i) => {
    const items = content.querySelectorAll('li');
    console.log(items);

    const maxVisibleItems = content.dataset.visible
      ? parseInt(content.dataset.visible)
      : 4;

    console.log(maxVisibleItems);

    items.forEach((item, index) => {
      if (index > maxVisibleItems - 1) {
        item.classList.add('hidden');
      }
    });

    if (items.length > maxVisibleItems) {
      const btnShowHide = btnShowHideArr[i];
      btnShowHide.classList.add('is-visible');

      btnShowHide.addEventListener('click', () => {
        btnShowHide.classList.toggle('active');
        items.forEach((item, index) => {
          if (index > maxVisibleItems - 1) {
            item.classList.toggle('hidden');
          }
        });

        if (btnShowHide.classList.contains('active')) {
          btnShowHide.textContent = 'Close more';
        } else {
          btnShowHide.textContent = 'View more';
        }
      });
    }
  });
}
