import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { autoPlacement, computePosition, flip, offset } from '@floating-ui/dom';
/*import SimpleBar from 'simplebar';*/

if (document.getElementsByClassName('services-section__slider')[0]) {
  const servicesSlidersArr = document.querySelectorAll(
    '.services-section__slider'
  );
  const servicesSlidersButtonsArr = document.querySelectorAll(
    '.services-section__slider-buttons'
  );
  servicesSlidersArr.forEach((sliderEl, sliderIdx) => {
    const servicesOpenBtnArr = sliderEl.querySelectorAll(
      '.services-section__item-icon'
    );
    const servicesOpenSecondBtnArr = sliderEl.querySelectorAll(
      '.services-section__item-icon-second'
    );
    const servicesTooltipArr = sliderEl.querySelectorAll(
      '.services-section__item-tooltip'
    );
    const servicesTooltipSecondArr = sliderEl.querySelectorAll(
      '.services-section__item-tooltip'
    );

    const pagination = sliderEl.querySelector('.swiper-pagination');
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let countLastVisibleSlide = 0;

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const servicesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      /*autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },*/
      navigation: {
        prevEl: servicesSlidersButtonsArr[sliderIdx].querySelector(
          '.swiper-button-prev'
        ),
        nextEl: servicesSlidersButtonsArr[sliderIdx].querySelector(
          '.swiper-button-next'
        ),
      },
      breakpoints: {
        390: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        451: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 4,
        },
        901: {
          spaceBetween: 16,
          slidesPerView: 5,
        },
        1025: {
          spaceBetween: 16,
          slidesPerView: 6,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 6,
        },
      },
      on: {
        init: function () {
          setTimeout(addAnimationClass, 100); // Delay to ensure Swiper is fully initialized
          setTimeout(startAnimationClassInterval, 100); // Delay to ensure Swiper is fully initialized
          /*setInterval(addAnimationClass, 4000);*/
        },
        slideChange: function () {
          setTimeout(addAnimationClass, 100); // Delay to ensure Swiper updates active index
        },
      },
    });

    function addAnimationClass() {
      const slidesPerView = servicesSwiper.params.slidesPerView;
      const activeIndex = servicesSwiper.activeIndex;
      const totalSlides = servicesSwiper.slides.length;

      for (let i = 0; i < slidesPerView + 1; i++) {
        const slideIndex = (activeIndex + i) % totalSlides;
        if (!servicesSwiper.slides[slideIndex].classList.contains('animated')) {
          servicesSwiper.slides[slideIndex].classList.add('animated');
        }
      }
    }
    function startAnimationClassInterval() {
      for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove('animated');
        servicesSwiper.slides[0].classList.add('animated');
      }

      let counter = 1;
      setInterval(() => {
        const slidesPerView = servicesSwiper.params.slidesPerView + 1;
        const activeIndex = servicesSwiper.activeIndex;
        const totalSlides = servicesSwiper.slides.length;

        const slideIndex = (activeIndex + counter) % totalSlides;
        if (!servicesSwiper.slides[slideIndex].classList.contains('animated')) {
          servicesSwiper.slides[slideIndex].classList.add('animated');
        }
        counter = (counter + 1) % slidesPerView;
      }, 500);
    }

    /*swiperObserver(servicesSwiper);*/

    function getLastVisibleIndex() {
      const activeIndex = servicesSwiper.activeIndex;
      const slidesPerView = servicesSwiper.params.slidesPerView;
      const visibleSlides = Math.min(
        servicesSwiper.slides.length,
        slidesPerView
      );
      const lastVisibleIndex = activeIndex + visibleSlides - 1;
      countLastVisibleSlide = lastVisibleIndex;
    }

    servicesSwiper.on('slideChange', function () {
      getLastVisibleIndex();
    });

    setTimeout(function () {
      getLastVisibleIndex();
    }, 100);

    slides.forEach((slide, i) => {});

    function positionTooltip(button, tooltip, idx) {
      let placement = 'right-end';
      if (idx === countLastVisibleSlide || idx === countLastVisibleSlide + 1) {
        if (window.innerWidth >= 551) {
          placement = 'left-end';
        } else {
          if (window.innerWidth >= 390) {
            placement = 'bottom';
          }
        }
      }
      computePosition(button, tooltip, {
        middleware: [offset(-10)],
        placement: placement,
      }).then(({ x, y }) => {
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    }

    function openTooltip(button, tooltip, idx) {
      tooltip.classList.add('active');
      positionTooltip(button, tooltip, idx);
      const servicesTooltipText = tooltip.querySelector(
        '.services-section__item-tooltip-text'
      );
      console.log(servicesTooltipText);
      if (!servicesTooltipText.getAttribute('data-simplebar')) {
        const simpleBarInstance = new SimpleBar(servicesTooltipText);
      }
      setTimeout(function () {
        const scrollsHorizontalArr = servicesTooltipText.querySelectorAll(
          '.simplebar-horizontal'
        );
        const scrollsVerticalArr = servicesTooltipText.querySelectorAll(
          '.simplebar-vertical'
        );
        if (scrollsHorizontalArr.length >= 1) {
          for (let i = 0; i < scrollsHorizontalArr.length; i++) {
            if (i !== 0) {
              scrollsHorizontalArr[i].remove();
              scrollsVerticalArr[i].remove();
            }
          }
        }
      }, 50);
    }

    servicesOpenBtnArr.forEach((btn, i) => {
      const parent = btn.parentElement;
      const secondBtn = parent.querySelector(
        '.services-section__item-icon-second'
      );
      const secondTooltip = slides[i].querySelector(
        '.services-section__item-tooltip-second'
      );
      const tooltipInitContent = servicesTooltipArr[i].innerHTML;

      btn.addEventListener('mouseenter', () => {
        servicesTooltipArr[i].innerHTML = '';
        servicesTooltipArr[i].innerHTML = tooltipInitContent;
        servicesTooltipArr[i].classList.remove('is-second-content');
        openTooltip(btn, servicesTooltipArr[i], i);
      });

      slides[i].addEventListener('mouseleave', (event) => {
        servicesTooltipArr[i].classList.remove('active');
        servicesTooltipArr[i].classList.remove('is-second-content');

        /*servicesTooltipArr[i].addEventListener('mouseenter', () => {
          openTooltip(btn, servicesTooltipArr[i], i);
        });*/
      });

      btn.addEventListener('click', () => {
        if (servicesTooltipArr[i].classList.contains('active')) {
          servicesTooltipArr[i].classList.remove('active');
          slides[i].style.zIndex = 'unset';
        } else {
          for (let j = 0; j < servicesTooltipArr.length; j++) {
            servicesTooltipArr[j].classList.remove('active');
            slides[j].style.zIndex = 'unset';
          }
          servicesTooltipArr[i].innerHTML = '';
          servicesTooltipArr[i].innerHTML = tooltipInitContent;
          openTooltip(btn, servicesTooltipArr[i], i);
          slides[i].style.zIndex = '10';
        }
      });

      if (secondBtn) {
        secondBtn.addEventListener('mouseenter', () => {
          servicesTooltipArr[i].innerHTML = '';
          servicesTooltipArr[i].innerHTML = secondTooltip.innerHTML;
          servicesTooltipArr[i].classList.add('is-second-content');
          openTooltip(btn, servicesTooltipArr[i], i);
        });

        secondBtn.addEventListener('click', () => {
          if (servicesTooltipArr[i].classList.contains('active')) {
            servicesTooltipArr[i].classList.remove('active');
            servicesTooltipArr[i].classList.remove('is-second-content');
            slides[i].style.zIndex = 'unset';
          } else {
            for (let j = 0; j < servicesTooltipArr.length; j++) {
              servicesTooltipArr[j].classList.remove('active');
              slides[j].style.zIndex = 'unset';
            }
            servicesTooltipArr[i].innerHTML = '';
            servicesTooltipArr[i].innerHTML = tooltipInitContent;
            servicesTooltipArr[i].classList.add('is-second-content');
            openTooltip(btn, servicesTooltipArr[i], i);
            slides[i].style.zIndex = '10';
          }
        });
      }
    });
  });
}
