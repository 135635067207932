import Swiper, { Navigation, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let sectorsSwiperWr = document.querySelectorAll('.candidate-services-swiper-wr');

sectorsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let tabs = el.querySelectorAll('.candidate-services-swiper-tab-item');
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, EffectFade],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoHeight: true,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      allowTouchMove: false,
      on: {
        slideChange: function (swiper) {
          let idx = swiper.activeIndex;
          tabs.forEach((tab, i) => {
            if (i != idx) {
              tab.classList.remove('active');
            } else {
              tab.classList.add('active');
            }
          });
          if (window.innerWidth < 551) {
            scrollToEl('.candidate-services-swiper-wr .swiper|500|120');
          }
        },
      },
    });

    tabs.forEach((tab, idx) => {
      tab.addEventListener('click', function () {
        this.classList.add('active');
        swiper.slideTo(idx);
        tabs.forEach((tab, i) => {
          if (i != idx) {
            tab.classList.remove('active');
          }
        });
        if (window.innerWidth < 551) {
          scrollToEl('.candidate-services-swiper-wr .swiper|500|120');
        }
      });
    });
  }
});