window.scrollToEl = function scrollToEl(targetID) {
  let config = [...targetID.split('|')];
  let duration = +config[1] && +config[1] != '' ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] != '' ? +config[2] : 0;
  let target = config[0];
  if ($(target).length) {
    $('html, body').animate(
      {
        scrollTop: $(target).offset().top - paddingTop,
      },
      duration
    );
    return false;
  }
};
/*onclick=scrollToEl("#get-in-touch-section|800|150")*/

if (document.querySelector('.count-up-block__suffix')) {
  const countSuffixArr = document.querySelectorAll('.count-up-block__suffix');
  const exclusions = ['+', 'k', '%'];

  countSuffixArr.forEach((suffix) => {
    if (!exclusions.includes(suffix.textContent)) {
      suffix.classList.add('ml');
    }
  });
}
