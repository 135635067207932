import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let solutionsSwiperWr = document.querySelectorAll('.solutions-swiper-wr');
solutionsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let cards = el.querySelectorAll('.solutions-card');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 'auto',
      speed: 800,
      spaceBetween: 15,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        550: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
    swiperObserver(swiper);

    cards.forEach((card) => {
      let toggle = card.querySelector('.toggle-btn');
      let text = card.querySelector('.solutions-card-description');

      // const updateToggleVisibility = () => {
      //   if (text.scrollHeight > text.clientHeight) {
      //     toggle.classList.remove('sr-only');
      //   } else {
      //     toggle.classList.add('sr-only');
      //   }
      // };

      if (card.classList.contains('active')) {
        text.style.maxHeight = text.scrollHeight + 'px';
      }

      // updateToggleVisibility();

      toggle.addEventListener('click', function () {
        if (card.classList.contains('active')) {
          text.style.maxHeight = '';
          text.style.display = 'block';
          setTimeout(function () {
            text.style.display = '-webkit-box';
          }, 500);
        } else {
          text.style.maxHeight = text.scrollHeight + 'px';
        }
        this.classList.toggle('active');
        card.classList.toggle('active');
      });
    });
  }
});
