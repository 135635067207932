import Swiper, { Navigation, Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils';

const expertiseSlider = document.querySelector('.expertise-slider');
const expertiseImageSlider = document.querySelector('.expertise-image-slider');

if (expertiseSlider && expertiseImageSlider) {
  const expertiseDescriptions = expertiseSlider.querySelectorAll(
    '.expertise-slider__item-description'
  );

  const expertiseImageSwiper = new Swiper(expertiseImageSlider, {
    slidesPerView: 1,
    // direction: 'vertical',
    allowTouchMove: false,
    breakpoints: {
      1025: {
        direction: 'vertical',
      },
    },
  });

  expertiseDescriptions.forEach((item) => {
    item.style.setProperty('--height', item.scrollHeight + 'px');
  });

  const expertiseSwiper = new Swiper(expertiseSlider, {
    slidesPerView: 'auto',
    // direction: 'vertical',
    slideToClickedSlide: true,
    // autoHeight: true,
    navigation: {
      prevEl: '.expertise-slider__mob-nav .expertise-slider__btn.prev',
      nextEl: '.expertise-slider__mob-nav .expertise-slider__btn.next',
    },
    on: {
      slideChange({ activeIndex }) {
        expertiseImageSwiper.slideTo(activeIndex);
      },
    },
    autoplay: {
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      delay: 2500,
    },
    breakpoints: {
      1025: {
        direction: 'vertical',
        autoHeight: false,
        navigation: {
          prevEl: '.expertise-slider__nav .expertise-slider__btn.prev',
          nextEl: '.expertise-slider__nav .expertise-slider__btn.next',
        },
      },
    },
    modules: [Navigation, Autoplay],
  });
}

const reviewsSlider = document.querySelector('.reviews-slider');

if (reviewsSlider) {
  const reviewsSwiper = new Swiper(reviewsSlider, {
    slidesPerView: 1,
    spaceBetween: 15,
    rewind: true,
    navigation: {
      prevEl: '.reviews-slider__nav .prev',
      nextEl: '.reviews-slider__nav .next',
    },
    autoplay: {
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      delay: 2500,
    },
    breakpoints: {
      550: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    
    modules: [Navigation, Autoplay],
  });

  swiperObserver(reviewsSwiper);
}

const counUpSlider = document.querySelector('.count-up-slider');

if (counUpSlider) {
  const countUpSwiper = new Swiper(counUpSlider, {
    slidesPerView: 2,
    spaceBetween: 31,
    rewind: true,
    autoplay: {
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      delay: 2500,
    },
    navigation: {
      prevEl: '.count-up-slider__btn.prev',
      nextEl: '.count-up-slider__btn.next',
    },
    modules: [Navigation, Autoplay],
  });
}
