import Swiper, { Autoplay, Navigation, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let sectorsSwiperWr = document.querySelectorAll('.sectors-section');
sectorsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
     let tabs = el.querySelectorAll('.sectors-swiper-tab-item')
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect:{
        crossFade: true,
      },
      autoHeight: true,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      allowTouchMove: false,
      on:{
        slideChange: function(swiper){
          let idx = swiper.activeIndex;
          tabs.forEach((tab, i)=>{
            if(i != idx){
              tab.classList.remove('active');
            }else{
              tab.classList.add('active');
            }
          })
          if(window.innerWidth<551){
            scrollToEl('.sectors-section .swiper|500|120');
          }
        }
      }
    });

    tabs.forEach((tab, idx)=>{
      tab.addEventListener('click', function(){
        this.classList.add('active');
        swiper.slideTo(idx);
        tabs.forEach((tab, i)=>{
          if(i != idx){
            tab.classList.remove('active');
          }
        })
        if(window.innerWidth<551){
          scrollToEl('.sectors-section .swiper|500|120');
        }
      })
    })
  }
});
