import Marquee from 'vanilla-marquee';

function initMarquee(marque, gallerySpeed = 20) {
  let marqueeGallery = document.querySelectorAll(marque);

  let pauseOnHover = true;

  if (window.innerWidth < 650 && gallerySpeed !== 0) {
    gallerySpeed = gallerySpeed / 2;
  }

  if (marqueeGallery) {
    marqueeGallery.forEach((gallery) => {
      let gal = gallery.querySelector('.life-gallery');
      let itemsArr = gallery.querySelectorAll('.marquee__item');

      let duplicated = true;

      if (itemsArr.length <= 2) {
        duplicated = false;
      }

      if (gal) {
        gal.style.minWidth = gal.scrollWidth + 'px';
      }
      setTimeout(function () {
        if (gallerySpeed === 0) {
          // If speed is 0, don't initialize Marquee
          gallery.classList.add('is-paused');
        } else {
          let marqueeEl = new Marquee(gallery, {
            duplicated: duplicated,
            startVisible: true,
            speed: gallerySpeed,
            gap: 30,
            pauseOnHover: true,
          });

          gallery.addEventListener('click', () => {
            if (!gallery.classList.contains('is-paused')) {
              marqueeEl.pause();
              gallery.classList.add('is-paused');
            } else {
              marqueeEl.resume();
              gallery.classList.remove('is-paused');
            }
          });

          document.addEventListener('click', (event) => {
            if (!gallery.contains(event.target)) {
              marqueeEl.resume();
              gallery.classList.remove('is-paused');
            }
          });
        }
      }, 10);
    });
  }
}

initMarquee('.about-us-gallery-section .marquee-gallery-item', 0);
initMarquee('.home-gallery-section .marquee-gallery-item', 20);
initMarquee('.join-team-page .marquee-gallery-item', 20);