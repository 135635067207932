const headerComponent = document.getElementsByClassName('header')[0];

window.addEventListener('load', () => {
  document.body.style.setProperty(
    '--header-height',
    headerComponent.clientHeight + 'px'
  );
});

// Header scroll
const scrollContainer = () => document.documentElement || document.body;

document.addEventListener('scroll', () => {
  if (scrollContainer().scrollTop > 50) {
    headerComponent.classList.add('scrolled');
  } else if (scrollContainer().scrollTop == 0) {
    headerComponent.classList.remove('scrolled');
  }
});

// menu handlers

// check mobile menu show/hide condition
const mobileMenuStartPoint = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue(
    '--mobile-menu-start-point'
  )
);
let isMobileMenuEnable =
  window.outerWidth <= mobileMenuStartPoint ||
  document.querySelector('.header-mobile');

// toggle menu handler
function menuToggleFunc() {
  headerComponent.classList.toggle('active');

  // LockScroll when burger open and enable when closed and enable scroll on menu
  if (scrollLock.getScrollState()) {
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');
  } else {
    scrollLock.enablePageScroll();
  }
}

// menu update function
function updateMenu() {
  isMobileMenuEnable =
    window.outerWidth <= mobileMenuStartPoint ||
    document.querySelector('.mobile-header');

  if (!isMobileMenuEnable) {
    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
    dropdownMenus.forEach((menu) => {
      menu.style.display = '';
    });

    const headerCloseWrapper = document.querySelector('.header-close-wrapper');
    headerCloseWrapper.classList.remove('active');

    const activeMenuItems = document.querySelectorAll('.menu-item.active');
    activeMenuItems.forEach((item) => {
      item.classList.remove('active');
    });

    const navbarNav = document.querySelector('.navbar-nav');
    navbarNav?.classList.remove('active');

    const menuToggle = document.querySelector('.menu-toggle');
    menuToggle?.classList.remove('active');

    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}

window.addEventListener('resize', updateMenu);
window.addEventListener('orientationchange', updateMenu);

// end of toggle menu handler

const burger = document.querySelector('.burger');
const dropshadow = document.querySelector('.header__mob-menu-dropshadow');

burger.addEventListener('click', menuToggleFunc);
dropshadow.addEventListener('click', menuToggleFunc);

// Hide and show Header when scroll up
function headerSticky() {
  let prevScrollPos = window.pageYOffset;

  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset;
    const headerHeight = headerComponent.scrollHeight;

    if (window.scrollY > 100) {
      if (prevScrollPos > currentScrollPos) {
        headerComponent.style.top = 0;
        headerComponent.classList.add('scrolled');
      } else {
        headerComponent.style.top = `-${headerHeight + 3}px`;
      }
    }

    if (window.scrollY === 0) {
      headerComponent.classList.remove('scrolled');
    }

    prevScrollPos = currentScrollPos;
  };

  if (window.scrollY !== 0) {
    headerComponent.classList.add('scrolled');
  }
}

// headerSticky();

const dropdownLinks = headerComponent.querySelectorAll(
  '.header__mob-menu-link.dropdown'
);

dropdownLinks.forEach((item) => {
  item.addEventListener('click', () => {
    item.classList.toggle('active');
  });
});