let accTabs = $('.services-list-item');
if (accTabs.length) {
  accTabs.each((idx, tab) => {
    $(tab).on('click', function () {
      $(this)
        .find('.services-list-item-content-wrapper')
        .stop(true, true)
        .slideToggle(function () {
          $(tab).toggleClass('active');
        });
    });
  });
}
