import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { autoPlacement, computePosition, flip, offset } from '@floating-ui/dom';
/*import SimpleBar from 'simplebar';*/

let timelineSwiperWr = document.querySelectorAll('.timeline-swiper-wr');
timelineSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    const servicesOpenBtnArr = el.querySelectorAll('.timeline-card-dot');
    const servicesTooltipArr = el.querySelectorAll(
      '.timeline-card-item-tooltip'
    );

    const slides = el.querySelectorAll('.swiper-slide');

    let countLastVisibleSlide = 0;

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 2,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        651: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
      /*autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },*/

      on: {
        init: function () {
          setTimeout(addAnimationClass, 100); // Delay to ensure Swiper is fully initialized
          setTimeout(startAnimationClassInterval, 100); // Delay to ensure Swiper is fully initialized
          /*setInterval(addAnimationClass, 4000);*/
        },
        slideChange: function () {
          setTimeout(addAnimationClass, 100); // Delay to ensure Swiper updates active index
        },
      },
    });

    function addAnimationClass() {
      const slidesPerView = swiper.params.slidesPerView;
      const activeIndex = swiper.activeIndex;
      const totalSlides = swiper.slides.length;

      for (let i = 0; i < slidesPerView + 1; i++) {
        const slideIndex = (activeIndex + i) % totalSlides;
        if (!swiper.slides[slideIndex].classList.contains('animated')) {
          swiper.slides[slideIndex].classList.add('animated');
        }
      }
    }
    function startAnimationClassInterval() {
      for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove('animated');
        swiper.slides[0].classList.add('animated');
      }

      let counter = 1;
      setInterval(() => {
        const slidesPerView = swiper.params.slidesPerView + 1;
        const activeIndex = swiper.activeIndex;
        const totalSlides = swiper.slides.length;

        const slideIndex = (activeIndex + counter) % totalSlides;
        if (!swiper.slides[slideIndex].classList.contains('animated')) {
          swiper.slides[slideIndex].classList.add('animated');
        }
        counter = (counter + 1) % slidesPerView;
      }, 750);
    }

    /*swiperObserver(swiper);*/

    function getLastVisibleIndex() {
      const activeIndex = swiper.activeIndex;
      const slidesPerView = swiper.params.slidesPerView;
      const visibleSlides = Math.min(swiper.slides.length, slidesPerView);
      const lastVisibleIndex = activeIndex + visibleSlides - 1;
      countLastVisibleSlide = lastVisibleIndex;
    }

    swiper.on('slideChange', function () {
      getLastVisibleIndex();
    });

    setTimeout(function () {
      getLastVisibleIndex();
    }, 100);

    slides.forEach((slide, i) => {});

    function positionTooltip(button, tooltip, idx) {
      let placement = 'right-end';
      if (idx === countLastVisibleSlide || idx === countLastVisibleSlide + 1) {
        if (window.innerWidth >= 551) {
          placement = 'left-end';
        } else {
          if (window.innerWidth >= 390) {
            placement = 'right-start';
          }
        }
      }
      computePosition(button, tooltip, {
        middleware: [offset(-10)],
        placement: placement,
      }).then(({ x, y }) => {
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    }

    function openTooltip(button, tooltip, idx) {
      tooltip.classList.add('active');
      positionTooltip(button, tooltip, idx);
      const servicesTooltipText = tooltip.querySelector(
        '.timeline-card-item-tooltip-text'
      );
      console.log(servicesTooltipText);
      if (!servicesTooltipText.getAttribute('data-simplebar')) {
        const simpleBarInstance = new SimpleBar(servicesTooltipText);
      }
      setTimeout(function () {
        const scrollsHorizontalArr = servicesTooltipText.querySelectorAll(
          '.simplebar-horizontal'
        );
        const scrollsVerticalArr = servicesTooltipText.querySelectorAll(
          '.simplebar-vertical'
        );
        if (scrollsHorizontalArr.length >= 1) {
          for (let i = 0; i < scrollsHorizontalArr.length; i++) {
            if (i !== 0) {
              scrollsHorizontalArr[i].remove();
              scrollsVerticalArr[i].remove();
            }
          }
        }
      }, 50);
    }

    servicesOpenBtnArr.forEach((btn, i) => {
      const parent = btn.parentElement;
      const tooltipInitContent = servicesTooltipArr[i].innerHTML;

      btn.addEventListener('mouseenter', () => {
        servicesTooltipArr[i].innerHTML = '';
        servicesTooltipArr[i].innerHTML = tooltipInitContent;
        servicesTooltipArr[i].classList.remove('is-second-content');
        openTooltip(btn, servicesTooltipArr[i], i);
      });

      slides[i].addEventListener('mouseleave', (event) => {
        servicesTooltipArr[i].classList.remove('active');
        servicesTooltipArr[i].classList.remove('is-second-content');

        /*servicesTooltipArr[i].addEventListener('mouseenter', () => {
          openTooltip(btn, servicesTooltipArr[i], i);
        });*/
      });

      btn.addEventListener('click', () => {
        if (servicesTooltipArr[i].classList.contains('active')) {
          servicesTooltipArr[i].classList.remove('active');
          slides[i].style.zIndex = 'unset';
        } else {
          for (let j = 0; j < servicesTooltipArr.length; j++) {
            servicesTooltipArr[j].classList.remove('active');
            slides[j].style.zIndex = 'unset';
          }
          servicesTooltipArr[i].innerHTML = '';
          servicesTooltipArr[i].innerHTML = tooltipInitContent;
          openTooltip(btn, servicesTooltipArr[i], i);
          slides[i].style.zIndex = '10';
        }
      });
    });
  }
});
