import './components/global.component.js';
import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/header.component.js';
import './components/buttons.component.js';

import './components/marquee.component.js';
import './components/animations.component.js';
import './components/services-accordion.component.js';
import './components/expertise.component.js';

/*swipers*/
import './components/sliders.component.js';
import './components/swiper-timeline.component.js';
import './components/swiper-diff.component.js';
import './components/swiper-team.component.js';
import './components/swiper-commitment.component.js';
import './components/swiper-partners-logo.component.js';
import './components/swiper-solutions.component.js';
import './components/swiper-steps.component.js';
import './components/swiper-sectors.component.js';
import './components/swiper-job-types.component.js';
import './components/swiper-ts.component.js';
import './components/swiper-more-news.component.js';
import './components/swiper-candidate-services.component.js';
import './components/swiper-services.component.js';
import './components/swiper-locations.component.js';
/*end of swipers*/

/*ranges*/
import './components/double-range-job-search.component.js';
/*end of ranges*/

import './libs/countUp.lib.js';

var popupFD = document.querySelector('#popup');
if (popupFD) {
  /*var popupTitle = popupFD.querySelector(".popup__title");*/
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    console.log(parentBlock);
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    popupFD.classList.add('active');
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    const scrollContentElement = document.querySelector('.popup__content');
    console.log(scrollContentElement);

    if (scrollContentElement) {
      const simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }

    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    /*const scrollBlock = scrollContentElement;
    const scrollsHorizontalArr = scrollBlock.querySelectorAll('.simplebar-horizontal');
    const scrollsVerticalArr = scrollBlock.querySelectorAll('.simplebar-vertical');
    if (scrollsHorizontalArr.length >= 1) {
      for (let i = 0; i < scrollsHorizontalArr.length; i++) {
        if (i !== 0) {
          scrollsHorizontalArr[i].remove();
          scrollsVerticalArr[i].remove();
        }
      }
    }*/
  };
}

window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};

/* Clients page solution cards button visibility */
const cards = document.querySelectorAll('.solutions-card');
cards.forEach((card) => {
  const description = card.querySelector('.solutions-card-description');
  const toggleBtn = card.querySelector('.toggle-btn');
  if (description.scrollHeight > 184) {
    toggleBtn.style.display = 'initial';
  } else {
    toggleBtn.style.display = 'none';
  }
});

console.log('TEST');

/* Animate benefits lines on view */
const benefitsSection = document.querySelector('.benefits');
const benefitsItems = document.querySelectorAll('.benefits__item');

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.2,
};

const animateItems = () => {
  benefitsItems.forEach((item, index) => {
    setTimeout(() => {
      item.classList.add('animate');
    }, index * 200);
  });
};

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      animateItems();
      observer.unobserve(entry.target);
    }
  });
}, observerOptions);

if (benefitsSection) {
  observer.observe(benefitsSection);
}
